import { AllFilter, getTierWeight, UserTier } from 'common-ui';
import dayjs, { Dayjs } from 'dayjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useApiAdminGetAgents } from 'src/api/agent/hooks/useApiAdminGet';
import { useApiGetAgent } from 'src/api/agent/hooks/useApiGet';
import {
  useApiArchiveAgent,
  useApiCopyAgent,
} from 'src/api/agent/hooks/useApiManage';

import {
  AGENT_CREATE_ROUTE,
  AGENT_EDIT_ROUTE,
  AGENT_VIEW_ROUTE,
  AGENTS_ROUTE,
} from 'src/constants/routes';

import { dateToUnixTimestamp } from 'src/helpers/datetime';

import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { setSelectedAgentInstance } from 'src/store/reducers/agentSlice';
import { getCurrentUser } from 'src/store/reducers/userSlice/selectors';

import { GetAgentResponse } from 'src/types/api/agent.types';
import {
  Agent,
  AgentStatus,
  AgentStatusFilter,
} from 'src/types/dao/agent.types';

export const useComponentProps = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>('');
  const [debouncedSearch, setDebouncedSearch] = useState<string>(search);
  const [searchOpen, setSearchOpen] = useState<boolean>(false);
  const [statusFilter, setStatusFilter] = useState<AgentStatusFilter>(
    AllFilter.ALL,
  );

  const [showSidePanel, setShowSidePanel] = useState<boolean>(false);
  const [archiveModalOpen, setArchiveModalOpen] = useState<boolean>(false);
  const [copyModalOpen, setCopyModalOpen] = useState<boolean>(false);
  const [activeAgent, setActiveAgent] = useState<Agent | null>(null);
  const [agentByQueryId, setAgentByQueryId] = useState<Agent | undefined>();

  const oneWeekAgo = new Date();
  oneWeekAgo.setDate(oneWeekAgo.getDate() - 6);
  oneWeekAgo.setUTCHours(0, 0, 1, 0);
  const [startDate, setStartDate] = useState<Dayjs>(dayjs(oneWeekAgo));
  const endOfToday = new Date();
  endOfToday.setUTCHours(23, 59, 59, 0);
  const [endDate, setEndDate] = useState<Dayjs>(dayjs(endOfToday));

  const {
    doRequest: adminGetAgents,
    responseData: adminAgents,
    isLoading,
  } = useApiAdminGetAgents();

  const { doRequest: getAgent, isLoading: agentLoading } = useApiGetAgent({
    onResponse: useCallback((data: GetAgentResponse) => {
      setAgentByQueryId(data);
    }, []),
    onError: useCallback(() => {
      history.push(AGENTS_ROUTE);
    }, [history]),
  });

  const loadAgents = useCallback(() => {
    const queryParams: {
      from_time: number;
      to_time: number;
      name: string;
      status?: string;
    } = {
      from_time: dateToUnixTimestamp(startDate.toDate()),
      to_time: dateToUnixTimestamp(endDate.toDate()),
      name: debouncedSearch,
    };

    if (statusFilter !== AllFilter.ALL) {
      queryParams.status = statusFilter;
    }

    adminGetAgents(queryParams, page);
  }, [adminGetAgents, debouncedSearch, statusFilter, startDate, endDate, page]);

  const { doRequest: archiveAgent, isLoading: isArchiveAgentLoading } =
    useApiArchiveAgent({
      onSuccess: useCallback(() => {
        setArchiveModalOpen(false);
        loadAgents();
      }, [loadAgents]),
    });

  const { doRequest: copyAgent, isLoading: isCopyAgentLoading } =
    useApiCopyAgent({
      onSuccess: useCallback(() => {
        setCopyModalOpen(false);
        loadAgents();
      }, [loadAgents]),
    });

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(search);
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [search]);

  useEffect(() => {
    loadAgents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch, statusFilter, startDate, endDate, page]);

  useEffect(() => {
    if (!isLoading) {
      const path = window.location.pathname;
      const agentId = path.split('/').pop();
      if (agentId && path.split('/').length > 2) {
        const agent = adminAgents?.data.find((agent) => agent.id === agentId);
        if (agent) {
          dispatch(setSelectedAgentInstance(agent));
          setShowSidePanel(true);
        } else {
          loadAgentById(agentId);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, adminAgents, dispatch, history]);

  const loadAgentById = async (agentId: string) => {
    try {
      await getAgent({ agentId });
      if (agentByQueryId) {
        dispatch(setSelectedAgentInstance(agentByQueryId));
        setShowSidePanel(true);
      }
    } catch (error) {
      console.error(error);
      history.push(AGENTS_ROUTE);
    }
  };

  const handleAgentInfoClick = async (agent: Agent) => {
    history.push(`${AGENT_VIEW_ROUTE}/${agent.id}`);
  };

  const onCloseSidePanel = () => {
    setShowSidePanel(false);
    dispatch(setSelectedAgentInstance(null));
    history.push(AGENTS_ROUTE);
  };

  const currentUser = useAppSelector(getCurrentUser);

  const userAccessTier = useMemo<number>(() => {
    return getTierWeight(currentUser?.access_tier);
  }, [currentUser]);

  const canCreate = useMemo<boolean>(() => {
    return userAccessTier >= getTierWeight(UserTier.GAME_EDITOR);
  }, [userAccessTier]);

  const handleCreate = useCallback(() => {
    history.push(AGENT_CREATE_ROUTE);
  }, [history]);

  const canEdit = useMemo<boolean>(() => {
    return userAccessTier >= getTierWeight(UserTier.ADMIN);
  }, [userAccessTier]);

  const quickEditingClick = useCallback(
    (id: string, event: React.MouseEvent) => {
      event.stopPropagation();
      history.push(`${AGENT_EDIT_ROUTE}/${id}`);
    },
    [history],
  );

  const handleEdit = useCallback(() => {
    history.push(`${AGENT_EDIT_ROUTE}/${activeAgent?.id}`);
  }, [activeAgent?.id, history]);

  const canCopy = useMemo<boolean>(() => {
    return userAccessTier >= getTierWeight(UserTier.GAME_EDITOR);
  }, [userAccessTier]);

  const canArchive = useMemo<boolean>(() => {
    return (
      activeAgent?.status !== AgentStatus.ARCHIVED &&
      (userAccessTier >= getTierWeight(UserTier.ADMIN) ||
        (userAccessTier >= getTierWeight(UserTier.GAME_EDITOR) &&
          currentUser?.id === activeAgent?.owner_id))
    );
  }, [
    userAccessTier,
    currentUser?.id,
    activeAgent?.status,
    activeAgent?.owner_id,
  ]);

  const navigateToAgent = useCallback(
    (id: string, event: React.MouseEvent) => {
      event.stopPropagation();
      history.push(`${AGENT_VIEW_ROUTE}/${id}`);
    },
    [history],
  );

  return {
    search,
    setSearch,
    searchOpen,
    setSearchOpen,
    statusFilter,
    setStatusFilter,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    page,
    setPage,
    havingNextPage: adminAgents?.pagination.having_next_page,
    adminAgents: adminAgents?.data || [],
    isLoading,
    activeAgent,
    setActiveAgent,
    showSidePanel,
    handleAgentInfoClick,
    onCloseSidePanel,
    canCreate,
    handleCreate,
    canEdit,
    handleEdit,
    canArchive,
    archiveModalOpen,
    setArchiveModalOpen,
    archiveAgent,
    isArchiveAgentLoading,
    canCopy,
    copyModalOpen,
    setCopyModalOpen,
    copyAgent,
    isCopyAgentLoading,
    quickEditingClick,
    navigateToAgent,
    setShowSidePanel,
    getAgent,
  };
};
